
import { Component, Vue } from "vue-property-decorator";
import metaDataModule from "@/store/modules/metaDataModule";
import FuzzySearch from "fuzzy-search";
import { sort } from "fast-sort";
import tasqFeedbackModule from "@/store/modules/tasqFeedbackModule";

@Component
export default class TasqWellStatus extends Vue {
  // actionItems: any = [];

  sortActionsInAscendingOrder = true;


  hideCategegoriesSection = false;

  hideWellActionSection = false

  searchQuery = "";

  get selectedActions() {
    return tasqFeedbackModule.selectedForms;
  }

  selectActionOption(option) {
    let formExistIndex = -1;
    if (this.selectedActions && this.selectedActions.length) {
      // @ts-ignore
      formExistIndex = this.selectedActions.findIndex((form) => form.id === option.id);
    }

    if (formExistIndex > -1) {
      tasqFeedbackModule.removeSelectedFrom(formExistIndex);
    } else {
      tasqFeedbackModule.pushSelectedForm(option);
    }
  }

  isActionAlreadySelected(option) {
    return this.selectedActions.findIndex((form) => form.id === option.id) > -1
      ? true
      : false;
  }

  get filteredActions(): any {
    let forms: any = metaDataModule.formBuilderData;
    const key: any = "title";
    if (this.searchQuery) {
      const fuzzySearch = new FuzzySearch(forms, [key], {
        caseSensitive: false,
      });
      forms = fuzzySearch.search(this.searchQuery);
    }
    if (this.sortActionsInAscendingOrder) {
      return sort(forms).asc(key);
    }
    return sort(forms).desc(key);
  }

  toogleWellActions(){
    this.hideWellActionSection = true;
    this.$nextTick(() => {
      this.hideWellActionSection = false;
    })
  }

  get actionItems() {
    const sortKey: any = "title";
    let forms: any = metaDataModule.formBuilderData;
    forms = forms.map((form) => ({
      validateForToday: false,
      isCompleteForToday: false,
      ...form,
    }));
    forms = forms.reduce((r, a) => {
      if (!a.category) {
        a.category = "None";
      }
      r[a.category] = r[a.category] || [];
      r[a.category].push(a);
      return r;
    }, Object.create(null));
    return Object.keys(forms).map((key) => ({
      title: key,
      actions: this.sortActionsInAscendingOrder
        ? sort(forms[key]).asc(sortKey)
        : sort(forms[key]).desc(sortKey),
    }));
  }
}
